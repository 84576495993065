import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CandyService } from 'src/app/candy.service';
import { HeaderService } from 'src/app/global/header/header.service';
import { MessageService } from 'src/app/global/message/message.service';
import { MagentoService } from 'src/app/magento.service';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({height: '*', opacity: 0})),
      transition(':leave', [
          style({height: '*', opacity: 1, 'overflow':'auto'}),
          group([
              animate(400, style({height: 0})),
              animate('400ms', style({'opacity': '0', 'overflow':'hidden', 'overflow-y':'hidden'}))
          ])
      ]),
      transition(':enter', [
          style({height: '0', opacity: 0, overflow:'hidden'}),
          group([
              animate(400, style({height: '*'})),
              animate('400ms', style({'opacity': '1', 'overflow':'auto', 'overflow-y':'hidden'}))
          ])
      ])
  ])
	],
})
export class DeviceComponent implements OnInit {

  showTechSpec: boolean = true;
	showPopup: Boolean = false;
  popupGrade: string = null;

  path: Params = null;

  product: any = null;
  product_name: string = null;
  product_attributes: Array<any> = [];
  product_type: string = null;
  product_configurable_options: Array<any> = [];
  attributes: Array<any> = [];
  originalConfigSku: string;

  gradeOptions: Array<any> = [];
  memorySizes: Array<any> = [];
  colourOptions: Array<any> = [];
  networkOptions: Array<any> = [];
  vatOptions: Array<any> = [];

  selectedOptions: Array<any> = [];
  selectedNetworkIndex: number = null;
  selectedGradeIndex: number = null;
  selectedMemoryIndex: number = null;
  selectedColourIndex: number = null;
  selectedVATIndex: number = null;

  selectedQty: number = 1;

  currentDeviceType: string = 'Mobiles';

  isLoggedIn: boolean = false;

  simpleProductsWithOptions: Array<any> =[];

  coloursReady: boolean = false;

  constructor(private candy: CandyService , private route: ActivatedRoute, private headerService: HeaderService, private magento: MagentoService, private messageService: MessageService, private session: SessionService) { }

  ngOnInit() {
    this.headerService.setShowLoader(true);
    this.route.params.subscribe(params => {
      // check if the sku has a / because if so, this will be interpeting as another url level
      // grab and combine e.g. URL could show SAM_SM-A025F/DS so grab SAM_SM-A025F and DS and combine with /, send to endpoint to grab correct item
      this.getProductData();
    })
    if (this.session.isLoggedIn()) {
      this.isLoggedIn = true;
    }
  }

  getProductData() {
    this.headerService.setShowLoader(true);
    // Get product using GC API
    this.path = this.route.snapshot.params;
    // handle / in SKU name e.g. for SAM_SM-A025F/DS
    // refactored - sku no longer used for url, url is default product name with dashes
    var url = '';
    if (this.route.snapshot.params.extra) {
      // url = this.path['url'] + '/' + this.route.snapshot.params.extra
      url = this.path['url'] + '/' + this.route.snapshot.params.extra
    } else {
      url = this.path['url']
    }

    this.candy.createRequest('GET', `api/product/${this.path['brand']}/${url}`).subscribe(response => {
      if (response) {
        this.product = response['product'];
        this.product_name = this.product['attribute_data']['manufacturer'].webstore.en + ' ' + this.product['attribute_data']['name'].webstore.en
        this.product_attributes = this.product['option_data'];
        this.product['sku'] = this.product['attribute_data']['sku'].webstore.en
        
        this.candy.getProductImageUrl(this.product['attribute_data']['model-id'].webstore.en).then((res)=>{
          this.product['img_url'] = res;
        }).catch(error => {
          console.log(error);
        })

        this.headerService.setShowLoader(false);
      }
    }, error => {
      console.log(error);
    })
  }

  setPopupInfo(grade) {
    this.popupGrade = grade;
  }

  resetOptions(product) {
    delete product['filters'];
    delete product['price'];
    delete product['stock'];

    product['selected_grade'] = '';
    product['selected_memory'] = '';
    product['selected_memory_ready'] = false;
    product['selected_colour'] = '';
    product['selected_vat'] = '';
    // product['selected_stock'] = 0;
    product['selected_qty'] = 1;
    this.coloursReady = false;
  }

  setProductOption( product, grade? , memory? , colour? , vat?) {
    // sku is used for endpoint to determine the child products
    var sku = product.sku;

    if(grade) {
      grade = grade.replaceAll(' ', '-');
      if(grade === 'Pristine-A-Plus') {
        grade = grade.toLowerCase();
      }
    }

    // assign selected values to current product
    // default argument passed for any option is undefined - this way, only one function can be used to carry out all product selections
    var currentGrade = product['selected_grade'] = grade ? grade: product['selected_grade'];
    var currentMemory = product['selected_memory'] = memory ? memory: product['selected_memory'];
    var currentColour = product['selected_colour'] = colour ? colour.replace(' ', '-'): product['selected_colour'];
    var currentVAT = product['selected_vat'] = vat ? vat: product['selected_vat'];

    // in situation where user goes backwards on a selection, restart if a child item cannot be made at that point...
    // can check this if current selected option does NOT belong in current filters - if so, restart the request string...
    if (product['filters']) {
      if (grade && product['filters']['status'].indexOf('Grade ' + grade.replace('grade-','').toUpperCase()) == -1) {
        console.log('grade selected again, restart request');
        currentMemory = product['selected_memory'] = undefined;
        currentColour = product['selected_colour'] = undefined;
        currentVAT = product['selected_vat'] = undefined;
        delete product['price'];
        delete product['stock'];
      }
      else if (memory && product['filters']['memory'].indexOf(memory) == -1) {
        console.log('memory selected again, restart request');
        currentGrade = product['selected_grade'] = undefined;
        currentColour = product['selected_colour'] = undefined;
        currentVAT = product['selected_vat'] = undefined;
        delete product['price'];
        delete product['stock'];
      }
      else if (colour && product['filters']['colour'].indexOf(colour) == -1) {
        console.log('colour selected again, restart request');
        currentMemory = product['selected_memory'] = undefined;
        currentGrade = product['selected_grade'] = undefined;
        currentVAT = product['selected_vat'] = undefined;
        delete product['price'];
        delete product['stock'];
      }
      else if (vat && product['filters']['vat'].indexOf(vat.charAt(0).toUpperCase() + vat.substring(1)) == -1) {
        console.log('vat selected again, restart request');
        currentGrade = product['selected_grade'] = undefined;
        currentMemory = product['selected_memory'] = undefined;
        currentColour = product['selected_colour'] = undefined;
        delete product['price'];
        delete product['stock'];
      }
    }

    // handle N/A memory option. The / is misread as a new URL so tweak that if found
    if (currentMemory) {
      currentMemory = currentMemory.replace('N/A', 'na');
    }

    // carefully build request string adding query parameters only if selected yet
    var request = `api/search/product/?&sku=${sku}${currentGrade ? '&grade[]=' + currentGrade : ''}${currentMemory ? '&memory[]=' + currentMemory : ''}${currentColour ? '&colour[]=' + currentColour : ''}${currentVAT ? '&vat[]=' + currentVAT : ''}`;

    // in situation where user goes backwards on a selection, restart if a child item cannot be made at that point...
    // can check this if current selected option does NOT belong in current filters - if so, restart the request string...

    this.candy.createRequest("GET", request).subscribe(response => {
      // save current available variants to current product to filter relevant remaining options
      // response will return 2 arrays - one is filter, which informs of possible remaining options. Second is results, which is remaining child products
      product['filters'] = response.filters;
      product['variants'] = response.result;

      // if all 4 options selected, narrow down to one item
      if (product['selected_grade'] && product ['selected_memory'] && product['selected_colour'] && product['selected_vat']) {
        // only allow add to basket if stock minimum 1, else, show out of stock
        // if (response.result[0]['stock'] > 0) {
        //   product['selected_qty'] = 1;
        // }
        product['stock'] = response.result[0]['stock']
        product['price'] = response.result[0]['price'];
        product['selected_variant'] = response.result[0]['hashid'];
        product['selected_variant_sku'] = response.result[0]['sku'];
      }
    })
  }

  setSelectedQty(product, qty) {
    this.product['selected_qty'] = qty;
  }

  getSimpleProduct(product) {
    this.product = product;
    this.product_name = this.product['name'];
    this.product_type = this.product.type_id;
  }

  getImageUrl(modelID) {
    return 'https://o2-api.devicetradein.co.uk/api/cache/images/model/' + modelID + '_1_pv.png'
  }

  addToCart(product) {
    this.product['selected_qty'] = this.selectedQty;
    this.product['type_id'] = 'simple';

    this.headerService.setShowLoader(true);

    this.candy.addToCart(product).then(response => {
      this.headerService.setShowLoader(false);
      // prevent user adding if actual stock value in db is 0
      product['stock'] = product['stock'] - product['selected_qty'];
    }, error => {
      console.log(error);
      this.headerService.setShowLoader(false);
      this.messageService.showMessage(error, 'error');
    }).catch(error=>{
      console.log(error);
      this.headerService.setShowLoader(false);
    });
  }
}
